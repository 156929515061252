import React, { useEffect, useState } from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
  geocodeByPlaceId,
  getLatLng,
} from "react-places-autocomplete";
import { useFormContext, Controller } from "react-hook-form";

const AddressAutocomplete = ({ name, label, rules, className,onAddressChange }) => {
  const {
    control,
    formState: { errors },
    setValue,
    getValues,
    watch,
    trigger
  } = useFormContext();

  const [address, setAddress] = useState(getValues(name));
  const fieldVal = watch(name);

  useEffect(() => {
    handleChange(fieldVal);
  }, [fieldVal])

  const handleSelect = (address) => {
    setAddress(address);
    // console.log(address);
    geocodeByAddress(address)
      .then((results) => {
        const addressComponents = results[0].address_components;

        // Extract state, city, and zip code from the address components
        let addressArray = [];
        let city = "";
        let state = "";
        let zip = "";
        let postal_code = "";
        let modifiedAddress = address;

        for (const component of addressComponents) {
          const types = component.types;
          if (types.includes("street_number")) {
            addressArray.push(component.long_name); // Street number
          }
          if (types.includes("route")) {
            addressArray.push(component.long_name); // Route
          }
          // if (types.includes("neighborhood")) {
          //   addressArray.push(component.long_name); // Neighborhood
          // }
          if (types.includes("administrative_area_level_1")) {
            state = component.short_name; // State
            if (modifiedAddress.includes(component.short_name)) modifiedAddress = modifiedAddress.split(component.short_name)[0].trim();
            if (modifiedAddress.includes(component.long_name)) modifiedAddress = modifiedAddress.split(component.long_name)[0].trim();
          }
          if (types.includes("locality")) {
            city = component.long_name; // City
            if (modifiedAddress.includes(component.long_name)) modifiedAddress = modifiedAddress.split(component.long_name)[0].trim();
          }
          if (types.includes("postal_code")) {
            zip = postal_code = component.long_name; // Zip Code
          }
        }

        // Remove city, state, and zip code from the original address string
        for (const part of addressArray) {
          if (modifiedAddress.includes(part)) {
            modifiedAddress = modifiedAddress.split(part)[0].trim();
            break; // Stop after the first match
          }
        }
        // Join the remaining address parts with the modified address string
        const finalAddress = modifiedAddress!='' ? modifiedAddress +'\n'+ addressArray.join(", ") : addressArray.join(", ");
        
        // Pass values to the parent component via the single callback function
        if (onAddressChange) onAddressChange({ address:finalAddress,modifiedAddress, city, state, zip, postal_code });
        handleChange(finalAddress);
        // return getLatLng(results[0]);
      })
      // .then((latLng) => console.log("Success", latLng))
      .catch((error) => console.error("Error", error));
  };

  const handleChange = (value) => {
    setAddress(value);
    setValue(name, value);
    errors[name] && trigger(name);
    // console.log(value)
  }

  return (
    <div className={className}>
      <div className="position-relative">
      <label htmlFor={name} className="form-label">
        {label}
      </label>
      <PlacesAutocomplete
        value={address}
        onChange={handleChange}
        onSelect={handleSelect}
        searchOptions={{
          componentRestrictions: { country: 'us' }
        }}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <>
            <Controller
              name={name}
              control={control}
              defaultValue=""
              rules={rules}
              render={({ field }) => (
                <textarea
                  {...field}
                  {...getInputProps({
                    className: "form-control",
                    rows: 3,
                  })}
                />
              )}
            />
            
            <div className="autocomplete-dropdown-container">
              {loading && <div className="suggestion-item-active">Loading...</div>}
              {suggestions.map((suggestion) => {
                const className = suggestion.active
                  ? "suggestion-item-active"
                  : "suggestion-item";
                // inline style for demonstration purpose
                const style = suggestion.active
                  ? { cursor: "pointer" }
                  : { cursor: "pointer" };
                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className,
                      style,
                    })}
                  >
                    <span>{suggestion.description}</span>
                  </div>
                );
              })}
            </div>
          </>
        )}
        
      </PlacesAutocomplete>
      {errors[name] && <span className="error">{errors[name].message}</span>}
    </div>
    </div>
  );
};

export default AddressAutocomplete;
