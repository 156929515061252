import React, { useEffect, useState } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import TextField from '@mui/material/TextField';

dayjs.extend(customParseFormat);

function DateField(props) {
  const { control, formState: { errors }, setValue, watch } = useFormContext();

  const [fieldValue, setFieldValue] = useState();
  
  const fieldVal = watch(props.name);
  useEffect(() => {
    setFieldValue(dayjs(fieldVal, 'MM/DD/YYYY'));
  }, [fieldVal])

  const dateChange = (value) => {
    const formattedValue = dayjs(value).format('MM/DD/YYYY');
    setFieldValue(dayjs(formattedValue, 'MM/DD/YYYY'));
    // setValue(props.name, dayjs(formattedValue, 'MM/DD/YYYY'))
    // setFieldValue(formattedValue);
    setValue(props.name,formattedValue)
    // console.log(formattedValue);
    // console.log(dayjs(formattedValue, 'MM/DD/YYYY'));
  };

  return (
    <div className={props.className}>
      <label htmlFor={props.name} className="form-label">{props.label}</label>

      <Controller
        name={props.name}
        control={control}
        rules={props.rules}
        render={({ field }) => (
          <MobileDatePicker
            // {...field}
            className="form-control input-form-control"
            value={fieldValue}
            minDate={props.minDate} // Set the minimum date
            onChange={(newValue) => {
              dateChange(newValue);
            }}
            renderInput={(params) => <TextField {...params} />}
          />
        )}
      />

      {errors[props.name] && <span className='error'>{errors[props.name].message}</span>}
    </div>
  );
}

export default DateField;
