import React from "react";
import Swal from "sweetalert2";
import { MakeAxiosRequest } from "../utils/handler";
import withReactContent from "sweetalert2-react-content";
import successImage from "../Assets/Images/success.png";
import deniedImage from "../Assets/Images/denied.png";

export const SwalConfirm = async (props) => {
  const MySwal = withReactContent(Swal);

  // console.log(props)
  try {
    const result = await Swal.fire({
      title: props.title || "Confirm your Action",
      html: props.subText,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: props.confirmBtn || "Yes",
      customClass: { confirmButton: "btn btn-primary" },
      cancelButtonText: props.cancelBtn || "No",
      reverseButtons: true,
    });
    if (result.isConfirmed) {
      if(props.actionUrl){

      
      const res = await MakeAxiosRequest(
        "post",
        props.actionUrl,
        props.actionParams
      );
      console.log(res);
      if (res.status == 1) {
        props.successFn && props.successFn();
        // Swal.fire({
        //   icon: 'success', title: 'Success!', customClass: { confirmButton: 'btn btn-primary' }, text: props.successMsg || res.message, timer: 3000
        // });
        const customSuccessIcon = `<img className="mb-3" src="${successImage}">
          <h3 className="mt-4 mb-0 white d-block">${props.successMsg || res.message}</h3>`;

        MySwal.fire({
          html: customSuccessIcon, // Use custom HTML for the icon
          customClass: {
            popup: "custom-popup",
            title: "custom-title",
            content: "custom-content",
            confirmButton: "custom-confirm-button",
          },
          confirmButtonColor: "#7166D6",
          showConfirmButton: false, // Hide the OK button
          timer: 3000, // Auto-close after 3000 milliseconds (3 seconds)
        });
      } else {
        const customSuccessIcon = `<p className="mb-0 white">${res.message}</p>`;

        MySwal.fire({
          html: customSuccessIcon, // Use custom HTML for the icon
          icon: "error",
          // title: "Error",
          customClass: {
            popup: "custom-popup",
            title: "custom-title",
            content: "custom-content",
            confirmButton: "custom-confirm-button",
          },
          confirmButtonColor: "#7166D6",
          showConfirmButton: true, // Hide the OK button
          // timer: 3000, // Auto-close after 3000 milliseconds (3 seconds)
        });
        // throw new Error(res.message);
      }
    }else{
      props.successFn();
    }
  }
  } catch (error) {
    const customSuccessIcon = `<p className="mb-0 white">${props.errorMsg || "An error occurred."}</p>`;

    MySwal.fire({
      html: customSuccessIcon, // Use custom HTML for the icon
      icon: "error",
      // title: "Error",
      customClass: {
        popup: "custom-popup",
        title: "custom-title",
        content: "custom-content",
        confirmButton: "custom-confirm-button",
      },
      confirmButtonColor: "#7166D6",
      showConfirmButton: true, // Hide the OK button
      // timer: 3000, // Auto-close after 3000 milliseconds (3 seconds)
    });
  }
};

export const SwalError = async (props) => {
  const MySwal = withReactContent(Swal);

  const customSuccessIcon = `
  <p className="mb-0 white">${props.msg}</p>
`;

  MySwal.fire({
    html: customSuccessIcon, // Use custom HTML for the icon
    icon: "error",
    // title: "Error",
    customClass: {
      popup: "custom-popup",
      title: "custom-title",
      content: "custom-content",
      confirmButton: "custom-confirm-button",
    },
    confirmButtonColor: "#7166D6",
    showConfirmButton: true, // Hide the OK button
    // timer: 3000, // Auto-close after 3000 milliseconds (3 seconds)
  }).then(result => {
    if (result.isConfirmed) {
      props.successFn && props.successFn();
    }
  });

  // MySwal.fire({
  //   icon: 'error', title: 'Error', customClass: { confirmButton: 'btn btn-primary' }, text: props.msg,
  // });
};

export const SwalSuccess = async (props) => {
  const MySwal = withReactContent(Swal);

  const customSuccessIcon = `
    <img src="${successImage}" alt="Success Icon">
    <h3 className="mt-4 mb-0 white">${props.msg}</h3>
    ${props.subText ? `<p className="mt-3 mb-0 white">${props.subText}</p>` : ''}
  `;

  MySwal.fire({
    html: customSuccessIcon, // Use custom HTML for the icon
    customClass: {
      popup: "custom-popup",
      title: "custom-title",
      content: "custom-content",
      confirmButton: "custom-confirm-button",
    },
    confirmButtonColor: "#7166D6",
    showConfirmButton: props.showbtn, // Hide the OK button
    confirmButtonText: props.btnText || 'OK', // Set the text for the confirm button
    timer: props.showbtn ? null : 3000, // Auto-close after 3000 milliseconds if button is not shown
  }).then(result => {
    console.log(result);
    if (result.isConfirmed) {
      props.successFn();
    }
  });
};

export const SwalWarning = async (props) => {
  const MySwal = withReactContent(Swal);

  const customSuccessIcon = `
     <div>
      <i className="swal2-icon swal2-warning swal2-icon-show" style="display: flex;"></i>
      <p className="mt-4 mb-0 white">${props.msg}</p>
    </div>
  `;

  MySwal.fire({
    title: 'Warning',
    html: customSuccessIcon, // Use custom HTML for the icon
    customClass: {
      popup: "custom-popup",
      title: "custom-title",
      content: "custom-content",
      confirmButton: "custom-confirm-button",
    },
    confirmButtonColor: "#7166D6",
    showConfirmButton: true, // Hide the OK button
    // timer: 3000, // Auto-close after 3000 milliseconds (3 seconds)
  });
};

export const deleteAccountSwal = async (props) => {
  const MySwal = withReactContent(Swal);

  try {
    const customIcon = `
    <img src="${deniedImage}" alt="Success Icon">
    <h3 className="mt-4 mb-0 white">${props.title}</h3>
    ${props.description ? `<p className="mt-3 mb-0 white">${props.description}</p>` : ''}
  `;

    const result = await MySwal.fire({
      // title: props.title || "Confirm your Action",
      html: customIcon, // Use custom HTML for the icon

      // html: props.subText,
      // icon: "warning",
      showCancelButton: false,
      showConfirmButton: props.show_pause_membership, // Hide the OK button
      showDenyButton: props.show_delete_account,  // This will show a second action button
      confirmButtonText: props.pauseMembershipLabel, // Text for the first action button
      denyButtonText: props.deleteButtonLabel,    // Text for the second action button
      cancelButtonText: "Cancel",
      reverseButtons: true,
      customClass: {
        popup: "custom-popup",
        title: "custom-title",
        content: "custom-content",
        confirmButton: "custom-confirm-button",
      },
      confirmButtonColor: "#7166D6",
    });

    if (result.isConfirmed) {
      // First action
      props.membershipAction && props.membershipAction(props.membership_alert_message);
    } else if (result.isDenied) {
      // Second action
      props.deleteAccountAction && props.deleteAccountAction();
    }
  } catch (error) {
    const customSuccessIcon = `<p className="mb-0 white">${props.errorMsg || "An error occurred."}</p>`;

    MySwal.fire({
      html: customSuccessIcon, // Use custom HTML for the icon
      icon: "error",
      // title: "Error",
      customClass: {
        popup: "custom-popup",
        title: "custom-title",
        content: "custom-content",
        confirmButton: "custom-confirm-button",
      },
      confirmButtonColor: "#7166D6",
      showConfirmButton: true, // Hide the OK button
      // timer: 3000, // Auto-close after 3000 milliseconds (3 seconds)
    });
  }
};