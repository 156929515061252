import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Notifications from "./Notifications";
import { useDispatch, useSelector } from "react-redux";
import { fetchNotificationCount, login, logout, update } from "../../features/authSlice";
import { sidebarData } from "../../Constants";
import { Helmet } from "react-helmet";
import { MakeAxiosRequest } from "../../utils/handler";
import dummyProfile from "../../Assets/Images/default-profile.png";
import "./Navbar.css";
import wallet from "../../Assets/Images/wallet.png";
import notification from "../../Assets/Images/notification.png";
import CreateRelationship from "../../Pages/Relationship/CreateRelationship";
import loaderFile from "../../Assets/Images/loader.gif";

function Navbar({toggleSidebar}) {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const accesstoken = localStorage.getItem("accesstoken");
  const [data, setData] = useState("");
  const logoutUser = async () => {
    const response = await MakeAxiosRequest("post", "/logout");
    dispatch(logout());
    navigate("/login");
  };
  useEffect(() => {
    handleClear();
  }, [location]);
  const getNotifications = async () => {
    setShowNotifications(false);
    dispatch(fetchNotificationCount());
    setIsLoaded(false);
    try {
      const response = await MakeAxiosRequest("post", "/notificationlist");
      // console.log(response)
      if (response.status == 1) {
        setIsLoaded(true);
        setShowNotifications(true);
        setData(response.data);
      } else {
        console.log(response);
      }
    } catch (error) {
      console.error("Error", error);
    }
  };

  const [modalShow, setModalShow] = useState(false);
  const showEdit = (key) => {
    // console.log(key);
    setModalShow(true);
  };

  const handleClose = () => {
    setModalShow(false);
  };
  const handleClear = () => {
    setCategory("");
    setSearchResults("");
    setNoRecordsFound(false);
  };
  const [showNotifications, setShowNotifications] = useState(false);
  const [count, setCount] = useState("");
  const [isLoaded, setIsLoaded] = useState(false);
  const [notifications, setNotification] = useState({});
  const [unReadNotifications, setUnReadNotifications] = useState(false);
  const [category, setCategory] = useState("");
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const authUser = useSelector((state) => state.auth.user);
  const notificationCount = useSelector(
    (state) => state.auth.notificationCount
  );
  const walletAmount = useSelector((state) => state.auth.walletAmount);

  const [searchResults, setSearchResults] = useState([]);
  const [noRecordsFound, setNoRecordsFound] = useState(false);
  // const unReadCount = useSelector(
  //   (state) => state.auth.pendingcount.unReadCount
  // );

  useEffect(() => {
    if (category.length == 0) setSearchResults("");
  }, [category]);
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      categorySearch();
    }
  };
  const categorySearch = async () => {
    try {
      const response = await MakeAxiosRequest("post", "/search", {
        searchterm: category,
      });
      // console.log(response)
      if (response.data.subcategories.length === 0) {
        setNoRecordsFound(true);
      } else {
        setSearchResults(response.data.subcategories);
        setNoRecordsFound(false);
      }
    } catch (error) {
      console.error("Error", error);
    }
    console.log(category);
  };
  const currentPath = location.pathname.split("/")[1];

  // Find the item in navbarTitle that matches the current route
  const pathArray = [currentPath, currentPath + "s"];
  const currentNavItem = sidebarData.find((item) =>
    pathArray.includes(item.link)
  );

  // Display the title from the matched item or a default value if not found
  const title = currentNavItem ? currentNavItem.title : "Page Not Found";
  const categorySelect = async (result) => {
    console.log("test");
    if (result.iscategory == 1) {
      navigate("/servicerequest/service/" + result.service_uuid);
    } else {
      navigate("/servicerequest/subcategory/" + result.service_uuid);
    }
    setSearchResults("");
  };

  // console.log(authUser)
  if (authUser?.first_name) {
    return (
      <>
        <Helmet>
          <title>Maxine | {title}</title>
        </Helmet>
        <div id="navbar-wrapper">
          <nav className="navbar navbar-inverse">
            <div className="container-fluid">
              <div className="navbar-header">
                <div className="row">
                  <div className="col-sm-6 col-md-5 col-lg-6 order-1 order-sm-0">
                    <div className="d-flex align-items-center">
                      <a
                        href="javascript:void(0)"
                        className="sidebar-toggle res-menu"
                        onClick={toggleSidebar}
                      >
                        <span className="material-symbols-outlined">menu</span>
                      </a>
                      <div className="input-group search-box mb-2 mb-sm-0">
                        <a
                          href="javascript:void(0)"
                          className="input-group-text"
                          id="basic-addon1"
                        >
                          <span
                            className="material-symbols-outlined"
                            onClick={categorySearch}
                          >
                            search
                          </span>
                        </a>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Search for categories"
                          aria-label="Username"
                          aria-describedby="basic-addon1"
                          value={category}
                          onChange={(e) => setCategory(e.target.value)}
                          onKeyDown={handleKeyDown}
                        />
                        {category && (
                          <button
                            className="input-clear-button"
                            onClick={handleClear}
                          >
                            &times;
                          </button>
                        )}
                        {noRecordsFound ? (
                          <ul className="list-group">
                            <li className="list-group-item text-center">
                              <small className="text-center">
                                No record found
                              </small>
                            </li>
                          </ul>
                        ) : (
                          searchResults.length > 0 && (
                            <ul className="list-group">
                              {searchResults.map((result, index) => (
                                <li
                                  key={index}
                                  className="list-group-item"
                                  onClick={() => categorySelect(result)}
                                >
                                  {result.service_name}
                                </li>
                              ))}
                            </ul>
                          )
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-7 col-lg-6 order-0 order-sm-1">
                    <div className="d-flex align-items-center justify-content-end justify-content-sm-end mb-2 mb-sm-0">
                      <div className="right-sec">
                        {walletAmount && (
                          <div className="wallet-amnt">
                            <Link to="/wallet" className="btn wallet-btn">
                              <img src={wallet} alt="wallet" />
                              <span>{walletAmount}</span>
                            </Link>
                          </div>
                        )}

                        <div className="notfctn-hd">
                          <div className="btn-group dro">
                            <button
                              type="button"
                              className="btn dropdown-toggle"
                              onClick={getNotifications}
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <img src={notification} alt="" />
                              {notificationCount > 0 && (
                                <span className="btn-ntfcn"></span>
                              )}
                            </button>
                            <ul className="dropdown-menu dropdown-menu-end ntfcn-dropdwn">
                              {!isLoaded ? (
                                <div>
                                  <center>
                                    <img
                                      src={loaderFile}
                                      style={{ width: "100px" }}
                                      alt="Loading..."
                                    />
                                  </center>
                                </div>
                              ) : (
                                showNotifications && (
                                  <Notifications
                                    notifications={data.notifications}
                                    count={data.notificationcount}
                                  />
                                )
                              )}
                              {/* <Notifications
                              notifications={data.notifications}
                              count={data.notificationcount}/> */}
                              {/* <div className="d-flex align-items-center justify-content-between py-2 px-2 mx-2 mb-2 border-primary border-bottom">
                                    <h4 className="text-center">Notifications</h4>
                                    <a href="#" className="link">Clear all</a>
                                  </div>
                                  
                                  <li>
                                    <button className="dropdown-item" type="button">
                                      <div className="d-flex">
                                        <span className="unread-notfctn"></span>
                                        <div>
                                          <h5>Service Provider is on the way</h5>
                                          <p>Your service provider will arrive in 30 minutes.</p>
                                          <small className="ntfcn-date d-block">5 min ago</small>
                                        </div>
                                      </div>
                                    </button>
                                  </li>
                                  <li>
                                    <button className="dropdown-item" type="button">
                                      <div className="d-flex">
                                        <span className="unread-notfctn"></span>
                                        <div>
                                          <h5>Service Provider is on the way</h5>
                                          <p>Your service provider will arrive in 30 minutes.</p>
                                          <small className="ntfcn-date d-block">5 min ago</small>
                                        </div>
                                      </div>
                                    </button>
                                  </li>
                                  <li>
                                    <button className="dropdown-item" type="button">
                                      <div className="d-flex">
                                        <span className="unread-notfctn"></span>
                                        <div>
                                          <h5>Service Provider is on the way</h5>
                                          <p>Your service provider will arrive in 30 minutes.</p>
                                          <small className="ntfcn-date d-block">5 min ago</small>
                                        </div>
                                      </div>
                                    </button>
                                  </li> */}
                            </ul>
                          </div>
                        </div>
                        <div className="profile-hd">
                          <div className="btn-group">
                            <button
                              type="button"
                              className="btn dropdown-toggle"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <div className="d-flex align-items-center">
                                <ProfileImage
                                  profileimg={authUser?.profileimg}
                                />
                                <div className="pftl-txt-hd pftl-txt">
                                  <span>Welcome</span>
                                  <p>{authUser?.first_name}</p>
                                </div>
                              </div>
                            </button>
                            <ul className="dropdown-menu dropdown-menu-end profile-drpdown">
                              <li>
                                <div className="d-flex align-items-center lst-prfl">
                                  <ProfileImage
                                    profileimg={authUser?.profileimg}
                                    className="me-2"
                                  />

                                  <div className="pftl-txt">
                                    <p className="mb-0">
                                      {authUser?.first_name}
                                    </p>
                                    <small className="mb-0">
                                      {authUser?.email}
                                    </small>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <Link
                                  className="dropdown-item"
                                  to="/relationship"
                                >
                                  <div className="d-flex align-items-center">
                                    <span className="material-symbols-outlined me-2">
                                    group
                                    </span>
                                    <p className="mb-0">Relationships</p>
                                  </div>
                                </Link>
                              </li>
                              <li>
                                <a
                                  className="dropdown-item"
                                  type="button"
                                  href="javascript:void(0)"
                                  onClick={showEdit}
                                >
                                  <div className="d-flex align-items-center">
                                    <span className="material-symbols-outlined me-2">
                                      person
                                    </span>
                                    <p className="mb-0">Edit Profile</p>
                                  </div>
                                </a>
                              </li>
                              <li>
                                <button
                                  className="dropdown-item"
                                  type="button"
                                  onClick={logoutUser}
                                >
                                  <div className=" d-flex align-items-center ">
                                    <span className="material-symbols-outlined me-2">
                                      logout
                                    </span>
                                    <p className="mb-0">Logout</p>
                                  </div>
                                </button>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </nav>
        </div>
        {modalShow && (
          <CreateRelationship
            showModal={modalShow}
            closeModal={handleClose}
            profileEdit={true}
          />
        )}
      </>
    );
  }
}

export function ProfileImage({ profileimg, className=''}) {
  return (
    <img
      className={`pfl-img ${className}`}
      src={profileimg || dummyProfile}
      onError={(e) => {
        e.target.onerror = null; // Prevent infinite loop if dummyProfile also fails
        e.target.src = dummyProfile; // Fallback to dummyProfile image
      }}
      alt="Profile"
    />
  );
}
export default Navbar;
