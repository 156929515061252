import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

function SidebarItems({ item, clickFn }) {
  const location = useLocation();
  const currentPath = location.pathname.split("/")[1];
  const subArray = ["paymentreports", "searchreports", "searchreports"];
  const isActive = subArray.includes(currentPath)
    ? "nav-link parentnav "
    : "nav-link collapsed";
  const [items, setItems] = useState([]);
  const notificationCount = useSelector((state) => state.auth.notificationCount);

  return (
    <>
      <li
        className={
          currentPath === item.type ||
          currentPath + "s" === item.type ||
          currentPath + "es" === item.type ||
          currentPath === item.type + "s"
            ? "active"
            : ""
        }
      >
        <div>
          {item.link ? (
            <Link
              to={item.link}
              title={item.title}
              onClick={
                item.type == currentPath
                  ? () => window.location.reload()
                  : undefined
              }
            >
              <SidebarContent item={item} notificationCount={notificationCount}/>
            </Link>
          ) : (
            <a href="javascript:void(0)" onClick={() => clickFn(item)}>
              <SidebarContent item={item} notificationCount={notificationCount}/>
            </a>
          )}
        </div>
      </li>
    </>
  );
}

function SidebarContent({ item,notificationCount }) {

  return (
    <>
      <img className="dash-icon" src={item.icon} />
      <p className="mb-0" style={{ color: item.text_color }}>
        {item.title}
        {(item.showCount && item.type==="notification" && notificationCount>0) && <span className="count">{notificationCount}</span>}
      </p>
    </>
  );
}
export default SidebarItems;
