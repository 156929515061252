import React from 'react'
import { useNavigate } from 'react-router-dom';

function CategoryCard({item}) {

  const navigate = useNavigate();

  return (
    <div className="col-6 col-sm-6 col-md-4 col-lg-2 mb-3">
        <div className="card" onClick={()=>navigate('/servicerequest/service/'+item.service_uuid)}>
            <img src={item.image_path} loading='lazy'/>
            <p className="dark py-2 mb-0">{item.service_name}</p>
        </div>
    </div>
  )
}

export default CategoryCard