import React, { useEffect, useState } from "react";
import { sidebarData } from "../../Constants";
import SidebarItems from "./SidebarItems";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Logo from "../../Assets/Images/logo-white.png";
import ResLogo from "../../Assets/Images/logo-res.png";

import toggle from "../../Assets/Images/dash-icons/toggle.png";
import { MakeAxiosRequest } from "../../utils/handler";
import { deleteAccountSwal, SwalConfirm } from "../SwalFns";
import { SettingsEthernetOutlined } from "@mui/icons-material";
import { changeMembership, fetchNotificationCount, fetchWalletAmount, logout, updateProfile } from "../../features/authSlice";
import DeleteAccountModal from "../modals/DeleteAccountModal";

function Sidebar({toggleSidebar}) {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const membershipStatusChange = useSelector((state) => state.auth.membershipStatusChange);
  const [settingsData, setSettingsData] = useState({});
  const user = localStorage.getItem("user");
  const data = JSON.parse(user);
  const [sideBarMenu, setSideBarMenu] = useState([]);
  const [deleteAcctModal, setDeleteAcctModal] = useState({showModal:false,data:'',isloading:true});

  const getSettings = async () => {
    try {
      const response = await MakeAxiosRequest("post", "/settings");
      if (response.status == 1) {
        setSettingsData(response.data);
        const combinedArray = sidebarData
          .filter((constData) => {
            // Check if the type is 'subscriptioncancel' or 'membership' and ensure it's in settingsdetails
            if (constData.type === "subscriptioncancel" || constData.type === "membership") {
              return response.data.settingsdetails.some(
                (item) => item.type === constData.type
              );
            }
            // Include all other types
            return true;
          })
          .map((constData) => {
            const matchingItem = response.data.settingsdetails.find(
              (item) => item.type === constData.type
            );
            return { ...constData, ...(matchingItem || {}) };
          });
        setSideBarMenu(combinedArray);
        // console.log(combinedArray);
        dispatch(updateProfile());
        dispatch(fetchNotificationCount());
        dispatch(fetchWalletAmount());
      } else {
        console.log(response);
      }
    } catch (error) {
      console.error("Error", error);
    }
  };

  // useEffect(() => {
  //    setSideBarMenu(sidebarData);
  //  }, [data, sidebarData]);

  useEffect(() => {
    getSettings();
  }, []);

  useEffect(() => {
    getSettings();
  }, [membershipStatusChange]);


  const logoutUser = async () => {
    dispatch(logout());
    navigate("/login");
  };

  const pauseMembership = (title,message="Are you sure")=>{
    SwalConfirm({
      title:title,
      subText: message,
      cancelBtn:"Cancel",
      actionUrl: "/cancelsubscription",
      successFn: ()=>dispatch(changeMembership()),
    });
  }

  
  const handleClick = async (item) => {
    if (item.type === "subscriptioncancel") {
      pauseMembership(item.title,item.message);
    } else if (item.type === "deleteaccount") {
      try {
        setDeleteAcctModal((prev) => ({
          ...prev,
          showModal: true,
          isloading:true
        }));
        const response = await MakeAxiosRequest("post", item.apiurl);

        if (response.status == 1) {
          console.log(response.data);
          setDeleteAcctModal((prev) => ({
            ...prev,
            data:response.data,
            isloading:false,
          }));
            // if(response.data.has_permission==1){
            //   deleteAccount();
            // }else{
            //   deleteAccountDenied(response.data.finaldata)
            // }
        } else {
          console.log(response);
        }
      } catch (error) {
        console.error("Error", error);
      }
    }
  };

  return (
    <>
      <aside className="sidebar-wrapper" id="sidebar-wrapper">
        <div className="sidebar-brand">
          <Link  to="/dashboard">
            <img className="logo" src={Logo} />
            <img className="logo-res" src={ResLogo} />
          </Link>
          
        </div>
        <div className="navbar-list">
          <ul className="sidebar-nav">
            {sideBarMenu
              ?.filter((item) => item.is_show)
              .map((item, index) => (
                <SidebarItems item={item} key={index} clickFn={handleClick} />
              ))}
          </ul>
          <ul className="sidebar-nav nav-toggle">
            <li onClick={toggleSidebar}>
              <div>
                <a href="javascript:void(0)" className="sidebar-toggle">
                  <img className="dash-icon" src={toggle} />
                  <p className="mb-0">Toggle Menu</p>
                </a>
              </div>
            </li>
          </ul>
        </div>
      </aside>
      {deleteAcctModal.showModal && <DeleteAccountModal 
        {...deleteAcctModal}
        closeModal={()=>setDeleteAcctModal({showModal:false,data:'',isloading:true})}
        pauseMembership={pauseMembership}
      />}
    </>
  );
}

export default Sidebar;
