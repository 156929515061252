import React from "react";
import { useFormContext, Controller } from "react-hook-form";

function CheckBoxField({ name, label, rules, options, className }) {
  const {
    control,
    setValue,
    formState: { errors },
  } = useFormContext();

  // Custom onChange handler to manage checkbox state
  const handleCheckboxChange = (field, value) => {
    const currentValue = field.value || [];
    if (currentValue.includes(value)) {
      // Remove the value if already checked
      setValue(
        name,
        currentValue.filter((id) => id !== value)
      );
    } else {
      // Add the value if not checked
      setValue(name, [...currentValue, value]);
    }
  };

  return (
    <div className={className}>
      <label htmlFor={name} className="form-label mb-3">
        {label}
      </label>

      <Controller
        name={name}
        control={control}
        defaultValue={[]}
        rules={rules}
        render={({ field }) => (
          <div className="row">
            {options?.map((option, index) => (
              <div key={index} className="col-md-6 mb-3">
              <div className="form-check form-checked">
              <input
                  type="checkbox"
                  value={option.id}
                  checked={field.value.includes(option.id)}
                  onChange={() => handleCheckboxChange(field, option.id)}
                  className="form-check-input"
                  id={name+`-checkbox-`+option.id}
                />
                <label className="form-check-label ms-1" htmlFor={name+`-checkbox-`+option.id}>{option.name}</label>
              </div>
              </div>
            ))}
          </div>
        )}
      />

      {errors[name] && <span className="error">{errors[name].message}</span>}
    </div>
  );
}

export default CheckBoxField;
