import React, { useState } from 'react'
import ModalBox from './ModalBox'
import deleteIcon from '../../Assets/Images/delete.png'
import deniedIcon from '../../Assets/Images/denied.png'
import { MakeAxiosRequest } from '../../utils/handler';
import { SwalError, SwalSuccess } from '../SwalFns';
import { useNavigate } from 'react-router-dom';

function DeleteAccountModal(props) {
    // console.log(props.data?.has_permission)
    const navigate = useNavigate();
    const [showDelete, setShowDelete] = useState(false);

    const Confirm = async () => {
        try {
          const response = await MakeAxiosRequest("post", "/deleteaccount");
          if (response.status == 1) {
            SwalSuccess({ msg: response.message });
            navigate("/login");
            // console.log(combinedArray);
          } else {
            SwalError({ msg: response.message });
            // console.log(response);
          }
        } catch (error) {
          console.error("Error", error);
        }
      };
    

  return (
    <ModalBox
      isShow={props.showModal}
      handleClose={props.closeModal}
      modalSize="md"
      hasData={!props.isloading}
      hideClose={props.data?.has_permission||showDelete}
    >
        {(props.data?.has_permission || showDelete) ?
        <><div className="text-center">
        <img className="mb-3 w-50" src={deleteIcon}/>
      <h3 className="mt-4 mb-0 white d-block">Delete Account</h3>
      <p>Deleting your account will delete all records and you cannot retrieve it again</p>
      </div>
      <div className="mt-4">
        <button
          type="button"
          className="btn btn-danger rounded-pill w-100 p-2 mb-2"
          onClick={Confirm}
        >
          Delete My Account
        </button>
        <button
          type="button"
          className="btn btn-primary rounded-pill w-100 p-2"
          onClick={props.closeModal}
        >
          Cancel
        </button>
      </div></> :
      <>
      <div className="text-center">
      <img className="mb-3 w-50" src={deniedIcon}/>
    <h3 className="mt-4 mb-0 white d-block">{props.data?.finaldata?.title}</h3>
    <p>{props.data?.finaldata?.description}</p>
    </div>
    <div className="mt-4">
    {props.data?.finaldata?.show_pause_membership===1 && <button
        type="button"
        className="btn btn-primary rounded-pill w-100 p-2 mb-2"
        onClick={()=>{
            props.closeModal();
            props.pauseMembership(props.data?.finaldata?.pauseMembershipLabel,props.data?.finaldata?.membership_alert_message)
        }}
      >
        {props.data?.finaldata?.pauseMembershipLabel}
      </button>}
      {props.data?.finaldata?.show_delete_account===1 && <button
        type="button"
        className="btn btn-primary rounded-pill w-100 p-2"
        onClick={()=>setShowDelete(true)}
      >
        {props.data?.finaldata?.deleteButtonLabel}

      </button>}
    </div>
    </>
        }
      
    </ModalBox>
  )
}

export default DeleteAccountModal