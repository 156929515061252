import React from 'react'
import ReactPaginate from "react-paginate";

function ListPagination(props) {
  const perPage=props.perpage || 10;
  return (
    <>
    {props.totalCount  > perPage &&

       <div className="row">
        <nav aria-label="Page navigation example">
        <ReactPaginate
          breakLabel={<span className="break-label">...</span>}
          nextLabel={<i className="fa-solid fa-angle-right"></i>}
          nextLinkClassName="page-link" // Class for the a element of the next button
          previousLabel={<i className="fa-solid fa-angle-left"></i>}
          previousLinkClassName="page-link" // Class for the a element of the previous button
          onPageChange={props.pageChange}
          pageRangeDisplayed={5}
          pageCount={Math.ceil(props.totalCount / perPage)}
          disableInitialCallback={true}
          renderOnZeroPageCount={null}
          forcePage={props.currentPage} // This will force the current page
          containerClassName="pagination" // Class for the ul element
          pageClassName="page-item" // Class for each li element
          pageLinkClassName="page-link" // Class for each a element inside li
          nextClassName="page-item" // Class for the li element of the next button
          previousClassName="page-item" // Class for the li element of the previous button
          activeClassName="active" // Optional: Class for the active page li
        />
      </nav>
    </div>
    }
    </>
  )
}

export default ListPagination