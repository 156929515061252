import { placeholder } from '@babel/types';
import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';

function InputField(props) {
  const { control, formState: { errors } } = useFormContext();

  return (
    <div className={props.className}>
      {props.label && <><label htmlFor={props.name} className="form-label">{props.label}</label></> }
      {/* {props.label && <><label htmlFor={props.name} className="form-label">{props.label}</label>{(props.rules && props.rules.required) && "*"}</> } */}
       

      {props.icon ? (
          <div className="input-group icon-form-lft">
            <span className="input-group-text" id="basic-addon1">{props.icon}</span>
            <Controller
          name={props.name}
          control={control}
          defaultValue=""
          rules={props.rules}
          render={({ field }) => (
            <input {...field} type="text" className="form-control" />
          )}
        />
          </div>
        ) : 
        <Controller
          name={props.name}
          control={control}
          defaultValue=""
          rules={props.rules}
          render={({ field }) => (
            <>
            {props?.isdisabled ? <div className='non-editable'>{field?.value}</div> :
              <input {...field} type="text" className="form-control"   placeholder={props.placeholder || undefined} disabled={props?.isdisabled}/>
            } 
            </>
          )}
        />
        
        
        }

      {errors[props.name] && <span className='error'>{errors[props.name].message}</span>}
    </div>
  );
}

export default InputField;
