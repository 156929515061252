import React, { useEffect, useRef, useState } from "react";
import logoWhite from "../../Assets/Images/logo-white.png";
import qrCode from "../../Assets/Images/qr-code.png";
import { extractNumbers, MakeAxiosRequest } from "../../utils/handler";
import { useForm, Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import OTPInput, { ResendOTP } from "otp-input-react";
import { useDispatch } from "react-redux";
import { login } from "../../features/authSlice";
import { isEmpty } from "lodash";
import { phoneRegex } from "../../Constants";
import { emailRegEx } from "../../Constants";
import InputMask from "react-input-mask";
import "intl-tel-input/build/css/intlTelInput.css";
import IntlTelInput from "intl-tel-input/react";
import { SwalSuccess } from "../../Components/SwalFns";

function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isregister, setRegister] = useState("");
  const [isButtonDisabled, setButtonDisabled] = useState(true);
  const [countrycodes, setCountryCodes] = useState([""]);
  const [isContinueDisabled, setContinueDisabled] = useState(false);
  const [loginType, setLoginType] = useState("phone");

  const [otpvalues, setOtpvalues] = useState({
    otp_uuid: "",
    otp: "",
    phone_number: "",
    countrycode: "",
    login_type: "phone",
  });
  const [OTP, setOTP] = useState("");
  const [otperror, setOtperror] = useState("");
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    reset,
    watch,
    control,
    setValue,
    trigger,
  } = useForm();
  const accesstoken = localStorage.getItem("accesstoken");

  useEffect(() => {
    if (!isEmpty(accesstoken) && accesstoken != "undefined") {
      // Redirect to dashboard page
      navigate("/dashboard");
    }
    getCountryCodes();
  }, []);

  const getCountryCodes = async () => {
    try {
      const response = await MakeAxiosRequest("post", "/getcountries");
      // console.log(response)
      if (response.status == 1) {
        const countryCodes = response.data.countryCodeList?.map((item) => ({
          // Transform each item as needed
          id: item.countryid,
          name: item.countrycode,
        }));
        setCountryCodes(countryCodes);
        setValue('countrycode', countryCodes[0].name); // Update the form value
      } else {
        console.log(response);
      }
    } catch (error) {
      console.error("Error", error);
    }
  };
  const handleInputChange = (name, value) => {
    const strippedNumber = value.replace(/^\+\d+\s/, "");
    setValue(name, strippedNumber); // Update the form value
    trigger(name); // Trigger validation for the field
  };
  const sentOtp = async (formData) => {
    try {
      setContinueDisabled(true);
      formData.phone_number = extractNumbers(formData.phone_number);
      formData.login_type = loginType;
      const res = await MakeAxiosRequest(
        "post",
        "/validatephonenumber",
        formData
      );
      setContinueDisabled(false);

      if (res.status == 1) {
        setOTP("");
        setRegister("otp");
        reset();
        setOtpvalues({
          ...otpvalues,
          phone_number: formData.phone_number,
          email: formData.email,
          otp_uuid: res.data.otp_uuid,
          otp: res.data.otp,
          countrycode: formData.countrycode,
          login_type: loginType,
        });
        SwalSuccess({ msg: res.message });
      } else if(res.status == 0) {
        setError("phone_number", { type: "custom", message: res.message });
      } else {
        setError("phone_number", { type: "custom", message: 'Something went wrong' });
      }
    } catch (error) {
      setError("phone_number", { type: "custom", message: 'Something went wrong' });
    }
  };

  const resendOtp = async () => {
    setContinueDisabled(true);
    const res = await MakeAxiosRequest("post", "/validatephonenumber", {
      countrycode: otpvalues.countrycode,
      phone_number: otpvalues.phone_number,
      login_type: otpvalues.login_type,
      email: otpvalues.email,
    });
    if (res.status == 1) {
      setOtpvalues({
        ...otpvalues,
        otp_uuid: res.data.otp_uuid,
      });
      SwalSuccess({ msg: res.message });
      // alert(res.data.otp);
    } else {
      alert(res.message);
    }
    setContinueDisabled(false);
  };

  const verifyOtp = async () => {
    if (OTP.length != "4") {
      setOtperror("please enter the OTP received");
    } else {
      const res = await MakeAxiosRequest("post", "/validatesigninotp", {
        otp: OTP,
        otp_uuid: otpvalues.otp_uuid,
        phone_number: otpvalues.phone_number,
        email: otpvalues.email,
        login_type: otpvalues.login_type,
      });
      if (res.status == 1) {
        if (res.data.userstatus != "register") {
          localStorage.setItem("accesstoken", res.data.accesstoken);
          localStorage.setItem(
            "userName",
            res.data.first_name + " " + res.data.last_name
          );
          localStorage.setItem("profileImage", res.data.profileimg);
          localStorage.setItem("email", res.data.email);
          localStorage.setItem("user", JSON.stringify(res.data));
          //   Dispatch the login action with user details
          dispatch(login(res.data));
          navigate("/dashboard");
        } else {
          setRegister("register");
        }
      } else {
        setOtperror(res.message);
        setOTP("");
      }
    }
    // setOtpvalues({...otpvalues,otp: OTP});
  };
  const [countryCode, setCountryCode] = useState("+1"); // Default to '+1'

  const watchedCountryCode = watch("countrycode");
  const watchedphonenumber = watch("phone_number");
  useEffect(() => {
    if (watchedCountryCode) {
      setCountryCode(watchedCountryCode);
      //   if (watchedCountryCode !== '+1') {

      //     console.log(watchedphonenumber);
      //     setValue('phone_number', extractNumbers(watchedphonenumber)); // Reset the phone number input
      // }
    }
  }, [watchedCountryCode]);
  const backButton = () => {
    setRegister("");
    setOtperror('');
  };
  const submitRegister = async (formData) => {
    formData.phone_number = extractNumbers(formData.phone_number);
    const res = await MakeAxiosRequest("post", "/register", {
      ...formData,
      otp_uuid: otpvalues.otp_uuid,
      login_type: otpvalues.login_type,
    });
    if (res.status == 1) {
      localStorage.setItem("accesstoken", res.data.accesstoken);
      localStorage.setItem("userName", res.data.name);
      localStorage.setItem("profileImage", res.data.profileimg);
      localStorage.setItem("user", JSON.stringify(res.data));
      //   Dispatch the login action with user details
      dispatch(login(res.data));
      navigate("/dashboard");
      console.log(res.data.accesstoken);
    } else {
      alert(res.message);
    }
  };

  return (
    <>
      <section className="signin-page">
        <div className="container-fluid p-0">
          <div className="row">
            <div className="col-12 col-md-4 col-lg-6 p-0">
              <div className="sign-in-left-sec">
                <div className="d-block text-center">
                  <img src={logoWhite} />
                  <div className="d-block text-center mt-3 mt-lg-5">
                    <img className="scan-code mt-lg-4" src={qrCode} />
                    <h6 className="mt-3 px-4">Scan this QR Code to Download Maxine Mobile App</h6>
                  </div>
                  
                </div>
              </div>
            </div>
            {(() => {
              switch (isregister) {
                case "otp":
                  return (
                    <>
                      <div className="col-12 col-md-8 col-lg-6">
                        <div className="sign-in-right-sec h-100 p-4">
                          <a
                            className="btn back-btn login-back"
                            onClick={backButton}
                          >
                            <i className="fa-solid fa-arrow-left"></i>Back
                          </a>

                          <div className="row align-items-center justify-content-center h-100">
                            <div className="col-sm-8 col-md-8 col-lg-8 col-xxl-6 ">
                              <form onSubmit={handleSubmit(verifyOtp)}>
                                <h1 className="mb-4">OTP Verification</h1>
                                <div className="form-group">
                                  <label className="mb-2">
                                  {loginType == "email" ? "Enter the OTP received in your Email." : "Enter the OTP received in your Mobile Number."}
                                  </label>
                                  <OTPInput
                                    className="otp-input"
                                    inputClassName="form-control"
                                    value={OTP}
                                    onChange={setOTP}
                                    secure={true}
                                    autoFocus
                                    OTPLength={4}
                                    otpType="number"
                                    disabled={false}
                                  />
                                </div>
                                {otperror && (
                                  <span className="error">{otperror}</span>
                                )}
                                <button
                                  type="submit"
                                  className="btn btn-primary rounded-pill w-100 mt-4"
                                  disabled={isContinueDisabled}
                                >
                                  Verify
                                </button>
                                <small className="d-block light mt-2 gray text-center">
                                  Didn’t receive code?
                                  <a
                                    href="javascript:void(0)"
                                    onClick={!isContinueDisabled && resendOtp}
                                    className="ms-1"
                                  >
                                    Resend Code
                                  </a>
                                </small>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  );
                case "register":
                  return (
                    <>
                      <div className="col-12 col-md-8 col-lg-6">
                        <div className="sign-in-right-sec p-4">
                          <a
                            className="btn back-btn login-back"
                            onClick={backButton}
                          >
                            <i className="fa-solid fa-arrow-left"></i>Back
                          </a>
                          <div className="row align-items-center justify-content-center h-100">
                            <div className="col-sm-8 col-md-8 col-lg-8 col-xxl-6">
                              <form onSubmit={handleSubmit(submitRegister)}>
                                <h1 className="mb-4">Let’s Sign Up</h1>
                                <div className="form-group mb-3">
                                  <label className="mb-2">
                                    Please complete your profile
                                  </label>
                                  <div className="">
                                    <input
                                      type="text"
                                      name="first_name"
                                      className="form-control "
                                      placeholder="First Name"
                                      {...register("first_name", {
                                        required: "Please enter first name",
                                      })}
                                    />
                                    {errors.first_name && (
                                      <span className="error">
                                        {errors.first_name.message}
                                      </span>
                                    )}
                                    <input
                                      type="text"
                                      name="last_name"
                                      className="form-control mt-3"
                                      placeholder="Last Name"
                                      {...register("last_name", {
                                        required: "Please enter last name",
                                      })}
                                    />
                                    {errors.last_name && (
                                      <span className="error">
                                        {errors.last_name.message}
                                      </span>
                                    )}
                                    {loginType == "phone" && (
                                      <>
                                        <input
                                          type="text"
                                          name="email"
                                          className="form-control mt-3"
                                          placeholder="Email"
                                          {...register("email", {
                                            required: "Please enter email",
                                            pattern: {
                                              value: emailRegEx,
                                              message:
                                                "Please enter a valid email address",
                                            },
                                            validate: {
                                              existCheck: async (fieldValue) => {
                                                const response = await MakeAxiosRequest("post","/checkcustomeremailexist",{ email: fieldValue });
                                                console.log(response);
                                                return response?.is_exist == 0 || "Email already exist";
                                              },
                                            },
                                          })}
                                        />
                                        {errors.email && (
                                          <span className="error">
                                            {errors.email.message}
                                          </span>
                                        )}
                                      </>
                                    )}
                                    {loginType == "email" && (
                                      <>
                                        <div className="phone-form mt-3">
                                          <div className="phone-code-dropdown">
                                            <select
                                              id="countryCodeSelect"
                                              className="form-select"
                                              name="countrycode"
                                              {...register("country_code")}
                                            >
                                              {countrycodes.map((code) => (
                                                <option
                                                  key={code.id}
                                                  value={code.name}
                                                >
                                                  {code.name}
                                                </option>
                                              ))}
                                            </select>
                                          </div>
                                          <Controller
                                            name="phone_number"
                                            control={control}
                                            rules={{
                                              required:
                                                "Phone number is required",
                                              pattern: {
                                                value: phoneRegex,
                                                message:
                                                  "Please enter a valid phone number",
                                              },
                                              validate: {
                                                existCheck: async (fieldValue) => {
                                                  const response = await MakeAxiosRequest("post","/checkcustomerphoneexist",{ phone_number: extractNumbers(fieldValue) });
                                                  console.log(response);
                                                  return response?.is_exist == 0 || "Phone number already exist";
                                                },
                                              },
                                            }}
                                            render={({ field }) => (
                                              <InputMask
                                                mask="(999) 999-9999"
                                                maskChar="" // Prevents showing underscores as placeholders
                                                {...field}
                                              >
                                                {(inputProps) => (
                                                  <input
                                                    {...inputProps}
                                                    type="text"
                                                    className={`form-control ${
                                                      errors.phone_number
                                                        ? "is-invalid"
                                                        : ""
                                                    }`}
                                                    id="exampleFormControlInput1"
                                                    placeholder="Mobile Number"
                                                  />
                                                )}
                                              </InputMask>
                                            )}
                                          />
                                        </div>
                                        {errors.phone_number && (
                                          <span className="error">
                                            {errors.phone_number.message}
                                          </span>
                                        )}
                                      </>
                                    )}
                                  </div>
                                </div>
                                <button
                                  type="submit"
                                  className="btn btn-primary mt-2 rounded-pill w-100"
                                >
                                  Save Profile
                                </button>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  );
                default:
                  return (
                    <>
                      <div className="col-12 col-md-8 col-lg-6">
                        <div className="sign-in-right-sec p-4 p-md-0">
                          <div className="row align-items-center justify-content-center h-100">
                            <div className="col-sm-10 col-md-9 col-lg-10 col-xl-8 col-xxl-7">
                              
                              <div className="login-grp-btns justify-content-center justify-content-sm-start mb-5">
                                <button
                                  type="button"
                                  className={`btn btn-primary-outline me-1 my-1 ${
                                    loginType == "phone" ? "active" : ""
                                  }`}
                                  onClick={() => {
                                    // setValue('phone_number', '');
                                    // setValue('countrycode', '+1');
                                    setLoginType("phone");
                                  }}
                                >
                                  <span class="material-symbols-outlined me-2">call</span> Login with Mobile
                                </button>
                                <button
                                  type="button"
                                  className={`btn btn-primary-outline my-1 mx-1 ${
                                    loginType == "email" ? "active" : ""
                                  }`}
                                  onClick={() => {
                                    // setValue('email', '');
                                    setLoginType("email");
                                  }}
                                >
                                 <span class="material-symbols-outlined me-2">mail</span> Login with Email
                                </button>
                              </div>

                              <form onSubmit={handleSubmit(sentOtp)}>
                                <h1 className="mb-4">Sign In / Register</h1>
                                <div className="form-group mb-3">
                                  {loginType == "phone" && (
                                    <>
                                      <label className="mb-2">
                                        Enter Your Mobile Number
                                      </label>
                                      <div className="phone-form">
                                        <span className="material-symbols-outlined">
                                          call
                                        </span>
                                        <div className="phone-code-dropdown">
                                          <select
                                            id="countryCodeSelect"
                                            className="form-select"
                                            name="countrycode"
                                            {...register("countrycode")}
                                          >
                                            {countrycodes.map((code) => (
                                              <option
                                                key={code.id}
                                                value={code.name}
                                              >
                                                {code.name}
                                              </option>
                                            ))}
                                          </select>
                                        </div>
                                        <Controller
                                          name="phone_number"
                                          control={control}
                                          rules={{
                                            required:
                                              "Phone number is required",
                                            pattern: {
                                              value: phoneRegex,
                                              message:
                                                "Please enter a valid phone number",
                                            },
                                          }}
                                          render={({ field }) => (
                                            <InputMask
                                              mask="(999) 999-9999"
                                              maskChar="" // Prevents showing underscores as placeholders
                                              {...field}
                                            >
                                              {(inputProps) => (
                                                <input
                                                  {...inputProps}
                                                  type="text"
                                                  className="form-control" 
                                                  id="exampleFormControlInput1"
                                                  placeholder="Mobile Number"
                                                />
                                              )}
                                            </InputMask>
                                          )}
                                        />
                                      </div>
                                      {errors.phone_number && (
                                        <span className="error">
                                          {errors.phone_number.message}
                                        </span>
                                      )}
                                    </>
                                  )}
                                  {loginType == "email" && (
                                    <>
                                      <label className="mb-2">
                                        Enter Your Email
                                      </label>
                                      <div className="phone-form">
                                        <span className="material-symbols-outlined">
                                          mail
                                        </span>

                                        <Controller
                                          name="email"
                                          control={control}
                                          rules={{
                                            required: "Email is required",
                                            pattern: {
                                              value: emailRegEx,
                                              message:
                                                "Please enter a valid email",
                                            },
                                          }}
                                          render={({ field }) => (
                                            <input
                                              {...field}
                                              type="text"
                                              className={`form-control ${
                                                errors.email ? "is-invalid" : ""
                                              }`}
                                              id="exampleFormControlInput1"
                                              placeholder="Email"
                                            />
                                          )}
                                        />
                                      </div>
                                      {errors.email && (
                                        <span className="error">
                                          {errors.email.message}
                                        </span>
                                      )}
                                    </>
                                  )}
                                  <small className="white pt-2 d-block">
                                    We will send you a verification code
                                  </small>
                                </div>
                                <button
                                  type="submit"
                                  className="btn btn-primary mt-2 rounded-pill w-100"
                                  onClick={()=>loginType == "phone" && trigger('phone_number')}
                                  disabled={isContinueDisabled}
                                >
                                  Send Code
                                </button>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  );
              }
            })()}
          </div>
        </div>
      </section>
    </>
  );
}

export default Login;
