import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';

const NumberField = (props) => {
  const { control, formState: { errors } } = useFormContext();
  const inputRef = React.createRef();

  const formatValue = (value) => {
    if (!value) return ''; // Return empty if there's no value

    const numericValue = parseFloat(value.replace(/[^0-9.]/g, '')); // Remove non-numeric characters except "."
    
    if (isNaN(numericValue)) return ''; // Return empty if the value is not a number

    // If there's a decimal point, limit the decimal places to two
    if (value.includes('.')) {
      return numericValue.toFixed(2);
    }

    return value; // Return the value as is if it doesn't need formatting
  };

  const handleChange = (e, onChange) => {
    const rawValue = e.target.value;
    const formattedValue = formatValue(rawValue);
    onChange(formattedValue); // Pass the formatted value to the form
  };

  return (
    <div className={props.className}>
      {props.label && (
        <label htmlFor={props.name} className="form-label">
          {props.label}
        </label>
      )}
      <div className={`${props.icon && 'input-group'}`}>
        <span className={`${props.icon && 'input-group-text'}`}>{props.icon}</span>
        <Controller
          name={props.name}
          control={control}
          defaultValue=""
          rules={props.rules}
          render={({ field: { onChange, onBlur, value, name, ref } }) => (
            <input
              type="text"
              value={value}
              onChange={(e) => handleChange(e, onChange)}
              onBlur={onBlur}
              placeholder={props.placeholder}
              ref={(e) => {
                inputRef.current = e;
                ref(e);
              }}
              className={`form-control ${errors[name] ? 'is-invalid' : ''}`}
              disabled={props?.isdisabled}
            />
          )}
        />
      </div>
      {errors[props.name] && <span className='error'>{errors[props.name].message}</span>}
    </div>
  );
}

export default NumberField;
