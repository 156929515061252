import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import InputMask from 'react-input-mask';

const NumberField = (props) => {
  const { control, formState: { errors } } = useFormContext();
  const inputRef = React.createRef();
  return (
    <div className={props.className}>
      {props.label && (
        <label htmlFor={props.name} className="form-label">
          {props.label}
          {/* {(props.rules && props.rules.required) && "*"} */}
        </label>
      )}
      <div className={`${props.icon && 'input-group'}`}>
      <span className={`${props.icon && 'input-group-text'}`}>{props.icon}</span>
      <Controller
        name={props.name}
        control={control}
        defaultValue=""
        rules={props.rules}
        render={({ field }) => (
          <InputMask
            {...field}
            mask={props.mask}
            placeholder={props.placeholder}
            maskChar="" // Prevents showing underscores as placeholders
            ref={inputRef} // Pass the ref to InputMask
            disabled={props?.isdisabled}
          >
            {(inputProps) => (
              <input
                {...inputProps}
                type={props.inputType || "text"}
                //  className={`form-control ${errors[props.name] ? 'is-invalid' : ''}`}
                className="form-control"
              />
            )}
          </InputMask>
        )}
      />
      </div>
      {errors[props.name] && <span className='error'>{errors[props.name].message}</span>}
    </div>
  );
}

export default NumberField;
