import React, { useState } from "react";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { useFormContext, Controller } from "react-hook-form";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';
import TextField from '@mui/material/TextField';

dayjs.extend(customParseFormat);

function HoursField(props) {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  // Function to parse the time string from the database
  const parseTime = (timeStr) => {
    // Return the time string as a dayjs object, no timezone adjustment
    return dayjs(timeStr, "hh:mm A");
  };

  return (
    <div className="col-md-12 mb-3">
    <div className="input-group d-block">
      <label className="hrs-label form-label">{props.label}</label>

      {/* <LocalizationProvider dateAdapter={AdapterDayjs}> */}
        <Controller
          name={props.name}
          control={control}
          rules={props.rules}
          render={({ field }) => {
            // Use the time from the database directly
            const value = field.value ? parseTime(field.value) : dayjs().format("hh:mm A");

            return (
              <MobileTimePicker
                {...field}
                value={value}
                onChange={(time) => field.onChange(time ? dayjs(time).format("hh:mm A") : '')}
                renderInput={(params) => <TextField {...params} />}
                className="form-control w-100 input-form-control"
                ampm={true} // Ensure 12-hour clock format is used
              />
            );
          }}
        />
      {/* </LocalizationProvider> */}

      {errors[props.name] && <span className='error'>{errors[props.name].message}</span>}
    </div>
    </div>
  );
}

export default HoursField;
