import { isEmpty } from 'lodash';
import React from 'react'
import {Navigate, useLocation} from "react-router-dom"

const ProtectedRoute = ({children}) => {
    let location = useLocation();
    const accesstoken = localStorage.getItem('accesstoken');
    const currentPath = location.pathname.split("/")[1];
    const landingPages = ["login"];
    
    if( (isEmpty(accesstoken) || accesstoken=='undefined') && !(landingPages.includes(currentPath)) ) {
        // Redirect to login page with the current location state
        return <Navigate to="/login" state={{ from: window.location.pathname }} replace />;
    }

 return children

};

export default ProtectedRoute;