import React, { useState } from "react";
import ListPagination from "./ListPagination";
import PageLayout from "../layout/PageLayout";
import Loader from "../loader/Loader";

function ListingPage(props) {
  // const handleSelectChange = (filterName, value) => {
  //   props.setLoadingStatus((prevParams) => ({ ...prevParams, table: true }));
  //   props.setListParams((prevParams) => ({
  //     ...prevParams,
  //     [filterName]: value,
  //   }));
  //   if (filterName === "search_term" || "tab")
  //     props.setListParams((prevParams) => ({ ...prevParams, offset: 0 }));
  // };

  const handlePageClick = (event) => {
    props.setLoadingStatus((prevParams) => ({ ...prevParams, table: true }));
    props.setListParams((prevParams) => ({
      ...prevParams,
      page: event.selected+1,
    }));
  };

  return (
    <>
    <PageLayout
    title={props.title}
    button={props.button}
    listing={true}
    >
            <div className="col-12 mb-3 mt-4">
              {props.loadingStatus ? (
                <Loader/>
              ) : (
                <>
                {/* <div className={props.tableclass ? props.tableclass : "table-responsive"}> */}
                  {props.children}

                  <ListPagination
                    pageChange={handlePageClick}
                    totalCount={props.totalCount}
                    perpage={props.listParams["limit"]}
                    currentPage={props.listParams.page-1}
                  />
                {/* </div> */}
                </>
              )}
            </div>
      </PageLayout>
    </>
  );
}

export default ListingPage;
